<template>
  <div>
    <v-container fluid class="pb-md-10 pb-0 mt-5">
      <v-container class="mx-auto px-0">
        <v-row class="responsive__style">
          <v-tabs v-model="model" class=" mb-5" color="--main-color" left>
            <v-tab href="#1" class="text-body-1 font-weight-bold text-center mr-5 ml-3">
              {{ $t('actions.news') }}
            </v-tab>
            <v-tab href="#2" class="text-body-1 font-weight-bold text-center">
              {{ $t('actions.anons') }}
            </v-tab>
            <v-tabs-items v-model="model">
              <v-tab-item value="1" class="py-4">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="8" lg="6" data-aos="fade-up" data-aos-anchor-placement="top"
                      data-aos-duration="1000" v-for="(item, index) in sortingNews1.slice(0, 1)" :key="index"
                      class="pa-2">
                      <div class="windowHover mx-auto my-3" @click="showDetailNew(item.id)">
                        <div class="positionRelative">
                          <v-img :src="baseUrl + item.image" class="hoverImage rounded"></v-img>
                          <div class="positionAbsolute white--text pa-3">
                            <!-- <p class="my-2 text-caption"
                        :class="{ 'red--text': item.type == 'Konsert', 'blue--text': item.type == 'Tadbir' }">{{ item.type
                        }}
                      </p> -->
<!--                            editingItem.step1?.definedCaseContent ? editingItem?.step1?.definedCaseContent?.split(' ').slice(0, 5).join(' ') : ''-->
                            <p class="text-caption font-weight-light mt-2 mb-0" style="line-height: 1.5;">
                              {{
                                getName({
                                  nameUz: item.title_uz ? item.title_uz?.split(' ').slice(0, 8).join(' ') : '',
                                  nameRu: item.title_ru ? item.title_ru?.split(' ').slice(0, 8).join(' ') : '',
                                  nameEn: item.title_en ? item.title_en?.split(' ').slice(0, 8).join(' ') : '',
                                })
                              }}...
                            </p>

                            <div class="text-body-2 py-2 d-flex justify-start align-center">
                              <div>
                                <v-icon class="mr-1" small color="white">mdi-calendar</v-icon>
                                <span>{{ item.created_at.slice(0, 10) }}</span>
                              </div>
                              <div class="ml-4">
                                <v-icon class="mr-1" small color="white">mdi-eye</v-icon>
                                <span>133</span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" data-aos="fade-up" data-aos-anchor-placement="top"
                      data-aos-duration="1000" v-for="(item, index) in sortingNews1.slice(1, 7)" :key="index"
                      class="pa-2">
                      <div class="windowHover mx-auto my-3" @click="showDetailNew(item.id)">
                        <div class="positionRelative">
                          <v-img :src="baseUrl + item.image" class="hoverImage rounded"></v-img>

                          <div class="positionAbsolute white--text pa-3">
                            <!-- <p class="my-2 text-caption"
                        :class="{ 'red--text': item.type == 'Konsert', 'blue--text': item.type == 'Tadbir' }">{{ item.type
                        }}
                      </p> -->
                            <p class="text-caption font-weight-light mt-2 mb-0" style="line-height: 1.5;">
                              {{
                                getName({
                                  nameUz: item.title_uz ? item.title_uz?.split(' ').slice(0, 8).join(' ') : '',
                                  nameRu: item.title_ru ? item.title_ru?.split(' ').slice(0, 8).join(' ') : '',
                                  nameEn: item.title_en ? item.title_en?.split(' ').slice(0, 8).join(' ') : '',
                                })
                              }}...
                            </p>

                            <div class="text-caption py-2 d-flex justify-start align-center">
                              <div>
                                <v-icon class="mr-1" small color="white">mdi-calendar</v-icon>
                                <span>{{ item.created_at.slice(0, 10) }}</span>
                              </div>
                              <div class="ml-4">
                                <v-icon class="mr-1" small color="white">mdi-eye</v-icon>
                                <span>207</span>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="2" class="py-4">
                <v-container fluid>
                  <v-row v-if="sortingNews2.length">
                    <v-col cols="12" sm="6" md="8" lg="6" data-aos="fade-up" data-aos-anchor-placement="top"
                      data-aos-duration="1000" v-for="(item, index) in sortingNews2.slice(0, 1)" :key="index"
                      class="pa-2">
                      <div class="windowHover mx-auto my-3" @click="showDetailNew(item.id)">
                        <div class="positionRelative">
                          <v-img :src="baseUrl + item.image" class="hoverImage rounded"></v-img>
                          <div class="positionAbsolute white--text pa-3">
                            <!-- <p class="my-2 text-caption"
                    :class="{ 'red--text': item.type == 'Konsert', 'blue--text': item.type == 'Tadbir' }">{{ item.type
                    }}
                  </p> -->
                            <p class="text-caption font-weight-light mt-2 mb-0" style="line-height: 1.5;">
                              {{
                                getName({
                                  nameUz: item.title_uz ? item.title_uz?.split(' ').slice(0, 8).join(' ') : '',
                                  nameRu: item.title_ru ? item.title_ru?.split(' ').slice(0, 8).join(' ') : '',
                                  nameEn: item.title_en ? item.title_en?.split(' ').slice(0, 8).join(' ') : '',
                              })
                              }}...
                            </p>

                            <div class="text-body-2 py-2 d-flex justify-start align-center">
                              <div>
                                <v-icon class="mr-1" small color="white">mdi-calendar</v-icon>
                                <span>{{ item.created_at.slice(0, 10) }}</span>
                              </div>
                              <div class="ml-4">
                                <v-icon class="mr-1" small color="white">mdi-eye</v-icon>
                                <span>133</span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" data-aos="fade-up" data-aos-anchor-placement="top"
                      data-aos-duration="1000" v-for="(item, index) in sortingNews2.slice(1, 7)" :key="index"
                      class="pa-2">
                      <div class="windowHover mx-auto my-3" @click="showDetailNew(item.id)">
                        <div class="positionRelative">
                          <v-img :src="baseUrl + item.image" class="hoverImage rounded"></v-img>

                          <div class="positionAbsolute white--text pa-3">
                            <!-- <p class="my-2 text-caption"
                    :class="{ 'red--text': item.type == 'Konsert', 'blue--text': item.type == 'Tadbir' }">{{ item.type
                    }}
                  </p> -->
                            <p class="text-caption font-weight-light mt-2 mb-0" style="line-height: 1.5;">
                              {{
                                getName({
                                  nameUz: item.title_uz ? item.title_uz?.split(' ').slice(0, 8).join(' ') : '',
                                  nameRu: item.title_ru ? item.title_ru?.split(' ').slice(0, 8).join(' ') : '',
                                  nameEn: item.title_en ? item.title_en?.split(' ').slice(0, 8).join(' ') : '',
                              })
                              }}...
                            </p>

                            <div class="text-caption py-2 d-flex justify-start align-center">
                              <div>
                                <v-icon class="mr-1" small color="white">mdi-calendar</v-icon>
                                <span>{{ item.created_at.slice(0, 10) }}</span>
                              </div>
                              <div class="ml-4">
                                <v-icon class="mr-1" small color="white">mdi-eye</v-icon>
                                <span>207</span>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" style="height: 30vh; display: flex; justify-content: center; align-items: center">
                      <v-progress-circular :size="70" :width="7" color="#0f172a" indeterminate></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
          <v-col cols="12" class="mb-5 px-3 text-end">
            <router-link :to="model == 1 ? `/news` : `/elonlar`">
              <v-btn color="var(--main-color)" class="white--text px-10">{{ $t('actions.readMore') }}...</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'HomeNews',
  data() {
    return {
      model: null,
      newsData: [
        { type: "Tadbir", image: "news2.jpg" },
        { type: "Tadbir", image: "news1.jpeg" },
        { type: "Tadbir", image: "news3.jpg" },
        { type: "Tadbir", image: "news4.jpg" },
        { type: "Tadbir", image: "news5.jpg" },
        { type: "Tadbir", image: "news6.jpg" },
        { type: "Tadbir", image: "news7.jpg" },
      ],
      annData: [
        { type: "Tadbir", image: "news4.jpg" },
        { type: "Tadbir", image: "news7.jpg" },
        { type: "Tadbir", image: "news5.jpg" },
        { type: "Tadbir", image: "news2.jpg" },
        { type: "Tadbir", image: "news3.jpg" },
        { type: "Tadbir", image: "news6.jpg" },
        { type: "Tadbir", image: "news1.jpeg" },
      ],
    }
  },
  created() {
    this.getNews()
  },
  computed: {
    ...mapGetters("news", ["news"]),
    sortingNews1() {
      let filterNews1 = this.news.filter((x) => x.category == 2)
      return filterNews1.sort((a, b) => b.id - a.id);
    },
    sortingNews2() {
      let filterNews2 = this.news.filter((x) => x.category == 1)
      return filterNews2.sort((a, b) => b.id - a.id);
    }
  },
  methods: {
    ...mapActions("news", ["getNews"]),
    showDetailNew(id) {
      this.$router.push({ name: "NewsDetail", query: { id: id } });
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.windowHover {
  width: 100%;
  max-width: 550px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .3);
  cursor: pointer;
}

.hoverImage {
  width: 100%;
  max-width: 550px;
  height: 315px;
  transition: all 0.5s ease;
}

@media screen and (max-width: 599px) {
  .windowHover {
    width: 430px;
  }

  .hoverImage {
    max-width: 430px;
  }
}

@media screen and (max-width: 475px) {
  .windowHover {
    width: 350px;
  }

  .hoverImage {
    max-width: 350px;
  }
}


.windowHover:hover .hoverImage {
  transform: scale(1.1);
}

.positionRelative {
  position: relative;
  overflow: hidden;
}

.positionAbsolute {
  position: relative;
  overflow: hidden;
}

.positionAbsolute {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top,
      rgba(12, 12, 12, 1),
      rgba(12, 12, 12, 0.89),
      rgba(19, 19, 19, 0.0191));
}

@media screen and (max-width: 425px) {
  .card__style {
    width: 100%;
    max-width: 350px;
  }

  .hoverImage {
    max-width: 400px;

  }
}

@media screen and (max-width: 400px) {
  .windowHover {
    width: 340px;
  }

  .hoverImage {
    max-width: 340px;
  }
}

@media screen and (max-width: 374px) {
  .windowHover {
    width: 300px;
  }

  .hoverImage {
    max-width: 300px;
  }
}

@media screen and (max-width: 336px) {
  .windowHover {
    width: 280px;
  }

  .hoverImage {
    max-width: 280px;
  }
}
</style>
