<template>
  <div>
    <v-container fluid class="pb-14">
      <v-container class="mx-auto px-2">
        <v-row class="responsive__style">
          <v-col cols="12" class="pt-2 mb-5">
            <p class="text-h6 font-weight-bold text-center" style="text-transform: uppercase;">{{ $t('actions.usefulLink') }}</p>
            <p class="iconColor" style="width: 60px; background-color: var(--main-color); margin: 0 auto; padding: 1px;"></p>
          </v-col>


          <v-col cols="12">
            <swiper class="swiper py-2" :options="swiperOption">
              <swiper-slide class="slide" v-for="(item, i) in usefulLink" :key="i">
                <div class="sponsor__effect py-3">
                  <a :href="item.link" target="_blank">
                  <div class="d-flex align-center px-3">
                    <v-img v-if="item.image" class="imgHover" width="50" :src="baseUrl + item.image" />
                    <v-img v-else class="imgHover" width="50" src="../assets/image/gerb.png" />
                    <p class="mb-0 font-weight-bold pl-1 my-2 black--text" style="font-size: 11px;">
                      {{ getName({
                        nameUz:item?.name_uz,
                        nameRu:item?.name_ru,
                        nameEn:item?.name_en
                      }) }}
                     </p>
                  </div>
                </a>
                </div>
              </swiper-slide>

            </swiper>
          </v-col>


<!--
          <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="pa-3"
          data-aos="fade-up"
          data-aos-anchor-placement="top"
          data-aos-duration="1000"
          v-for="(vm,index) in usefulLink"
          :key="index">
            <div class="sponsor__effect py-3">
              <a href="https://agmk.uz/index.php/ru/" target="_blank">
              <div class="d-flex px-3">
                <v-img v-if="vm.image" class="imgHover" width="50" :src="baseUrl + vm.image" />
                <v-img v-else class="imgHover" width="50" src="../assets/image/gerb.png" />
                <p class="mb-0 text-caption font-weight-bold pl-1 my-2 black--text">
                  {{ getName({
                    nameUz:vm?.name_uz,
                    nameRu:vm?.name_ru,
                    nameEn:vm?.name_en
                  }) }}
                 </p>
              </div>
            </a>
            </div>
          </v-col> -->

          </v-row>
      </v-container>
    </v-container>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
name:"UsefulLink",
components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation]
    }
  },
data(){
  return{
    swiperOption: {
        spaceBetween: 15,
        initialSlide: 0,
        autoplay: true,
        slidesPerView: 4,
        allowTouchMove: true,
        loop: false,
        effect: "slide",
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
    200: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 10
    }
  },
        speed: 1200,
      },
  }
},
created(){
  this.getUsefulLink();
},
computed:{
  ...mapGetters("usefulLink", ["usefulLink"])
},
methods:{
  ...mapActions("usefulLink", ["getUsefulLink"])
}
}
</script>

<style lang="scss" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  position: relative;
  font-size: 12px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

::v-deep .swiper-button-next {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
  transition: all 0.5s ease;
  opacity: 0 !important;
}

::v-deep .swiper-button-prev {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00000096;
  font-weight: 100;
  color: white;
  transition: all 0.5s ease;
  opacity: 0 !important;
}

.sponsor__effect {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
  transition: all 0.5s ease;
}
.textLink__style{
  width: 140px;
  font-size: 12px;
  font-weight: bold;
  padding-left: 12px;
  margin: 12px 0 0 0;
  color: black;
  font-family: "Roboto", sans-serif !important;
}
.sponsor__effect:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2);
}

.sponsor__effect:hover .imgHover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.imgHover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease;
}
</style>
